<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <form v-on:submit.prevent="updateRegion" class="form-fix">
        <span class="form-box-title">{{$t('region.region_text')}}</span>
        <div id="region-box" class="form-box grid-split-3column">
            <span class="form-box-name">{{$t('region.name_text')}}</span><b-form-input :state="states.name" v-model="region.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input" required></b-form-input>
            <span class="form-box-name">{{$t('region.email_text')}}</span><b-form-input v-model="region.email" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input" ></b-form-input>
            <span class="form-box-name">{{$t('region.company_text')}}</span><b-form-input v-model="region.company" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
            <span class="form-box-name">{{$t('general.address_text')}}</span><b-form-input v-model="region.address" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
            <span class="form-box-name">{{$t('general.city_text')}}</span><b-form-input v-model="region.city" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
            <span class="form-box-name">{{$t('general.postal_code')}}</span><b-form-input v-model="region.postal_code" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" maxlength="10"></b-form-input>
            <span class="form-box-name">{{$t('region.country_text')}}</span><b-form-select :disabled="permissions.country>0" :state="states.country_id" v-model="region.country_id" value-field="id" text-field="name" :options="countries" class="form-box-input" />
            <span class="form-box-name" v-if="region.country!=''">{{$t('general.region_text')}}</span><region-select v-if="region.country!=''" class="custom-select form-box-input" v-model="region.region"  :country="region.country" :region="region.region" />
            <span class="form-box-name">{{$t('region.principal_city')}}</span><b-form-input  v-model="region.principal_city" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input" ></b-form-input>
            <span class="form-box-name">{{$t('region.status_text')}} </span>
            <b-form-select :disabled="permissions.country>0" class="input-vmargins" v-model="region.subscription_status" :options="statusOptions"/>
              <b-form-checkbox v-model="region.core" class="second-column">
                {{$t('region.core_region')}}
              </b-form-checkbox>
         </div>
         <span v-if="region.country_id>0" class="form-box-title">{{$t('country.timezones_text')}}</span>
        <div v-if="region.country_id>0" class="form-box grid-auto-1column">
            <multiselect v-model="timezones" :style="{border : timezones.length == 0? '1px solid red' : ''}" :options="timezoneOptions" :placeholder="$t('country.choose_timezone')" label="name" track-by="name" class="blueselect country-multiselect" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="value" :close-on-select="false" :searchable="true">
          </multiselect>
        </div>

         <span v-if="permissions.country==0 || region.subscription_status!=1" class="form-box-title">{{$t('region.admin_logins')}}</span>
        <div v-if="permissions.country==0 || region.subscription_status!=1"  class="form-box grid-auto-1column">
            <span class="form-box-name left-text">{{$t('region.chapter_meetings')}}</span>
            <div class="region-chapter-box">
              <b-form-input :state="admin.chapter.name.length>0 && !chapterAdminNameInUse? null : false" v-model="admin.chapter.name"  @input="checkAdminName('chapterAdminNameInUse', admin.chapter.id, admin.chapter.name)" :placeholder="this.$t('global.username_text')" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="medium-width-input"  required></b-form-input>
              <div v-if="chapterAdminNameInUse" class="red-text">{{ $t("global.name_duplicate") }}</div>
              <b-form-input :state="(admin.chapter.password.length==0 || admin.chapter.password.length>8)? null : false" v-model="admin.chapter.password" :placeholder="this.$t('global.password_text')" type="password" class="medium-width-input" ></b-form-input>
              <b-form-input v-if="admin.chapter.password && admin.chapter.password.length>0" :placeholder="this.$t('global.retype_password')" class="medium-width-input" :state="admin.chapter.password===admin.chapter.password_confirm && admin.chapter.password_confirm.length>0" v-model="admin.chapter.password_confirm" type="password"  ></b-form-input>
            </div>
            <b-form-checkbox v-model="admin.has_startup" id="startup-chapter-checkbox">
                {{$t('region.startup_meetings')}}<span v-if="admin.has_startup" id="startup-chapter-note">*{{$t('region.startup_note')}}</span>
              </b-form-checkbox>
            <div v-if="admin.has_startup" class="region-chapter-box">
              <b-form-input :state="admin.startup.name && admin.startup.name.length>0 && !startupAdminNameInUse? null : false" v-model="admin.startup.name" @input="checkAdminName('startupAdminNameInUse', admin.startup.id, admin.startup.name)" :placeholder="this.$t('global.username_text')" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="medium-width-input"  required></b-form-input>
              <div v-if="startupAdminNameInUse" class="red-text">{{ $t("global.name_duplicate") }}</div>
              <b-form-input v-model="admin.startup.password" :state="admin.startup.password && (admin.startup.password.length==0 || admin.startup.password.length>8)? null : false" :placeholder="this.$t('global.password_text')" type="password" class="medium-width-input" ></b-form-input>
              <b-form-input v-if="admin.startup.password && admin.startup.password.length>0" :placeholder="this.$t('global.retype_password')"  class="medium-width-input" :state="admin.startup.password===admin.startup.password_confirm && admin.startup.password_confirm.length>0" v-model="admin.startup.password_confirm" type="password" ></b-form-input>
            </div>
           <b-form-checkbox v-model="admin.has_rst" id="rst-chapter-checkbox">
                {{$t('region.rst_meetings')}}<span v-if="admin.has_rst" style="margin-left:30px;font-size:12px;color:red;">*{{$t('region.startup_note')}}</span>
              </b-form-checkbox>
            <div v-if="admin.has_rst==1" class="region-chapter-box">
              <b-form-input :state="admin.rst.name && admin.rst.name.length>0 && !rstAdminNameInUse? null : false" v-model="admin.rst.name" @input="checkAdminName('rstAdminNameInUse', admin.rst.id, admin.rst.name)" :placeholder="this.$t('global.username_text')" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="medium-width-input"  required></b-form-input>
              <div v-if="rstAdminNameInUse" class="red-text">{{ $t("global.name_duplicate") }}</div>
              <b-form-input v-model="admin.rst.password" :state="admin.rst.password && (admin.rst.password.length==0 || admin.rst.password.length>8)? null : false" :placeholder="this.$t('global.password_text')" type="password" class="medium-width-input"  ></b-form-input>
              <b-form-input v-if="admin.rst.password && admin.rst.password.length>0" :placeholder="this.$t('global.retype_password')" class="medium-width-input" :state="admin.rst.password===admin.rst.password_confirm && admin.rst.password_confirm.length>0" v-model="admin.rst.password_confirm" type="password"  ></b-form-input>
            </div>
         </div>
        <span v-if="region.id>0 && (permissions.country==0)" class="form-box-title">{{$t('region.share_template')}}</span>
        <div v-if="region.id>0 && (permissions.country==0)" class="form-box grid-fitfirst-2column">
          <span  class="form-box-name">{{$t('region.region_text')}}</span><b-form-select v-model="region.template_region" :options="officialRegions" :disabled="true" class="mb-3" value-field="id" text-field="name"/>
          <b-button class="action-button column-span2 auto-margin" variant="clear-blue" v-on:click="showChangeTemplate=true;"><span>{{$t('global.button_change')}}</span></b-button>
         </div>
        <span v-if="region.id>0 && (permissions.country==0)" class="form-box-title">{{$t('region.copy_title')}}</span>
        <div v-if="region.id>0 && (permissions.country==0)"  class="form-box grid-split-3column">
          <span  class="form-box-name">{{$t('region.copy_to')}}</span><b-form-select v-model="copy.copy_to" :options="regionOptions" class="mb-3" /><span  class="form-box-name left-text">{{$t('region.agenda_slides')}}</span>
            <span  class="form-box-name">{{$t('region.copy_text')}}</span><b-form-select v-model="copy.copy_from" value-field="id" text-field="name" :options="regions" class="mb-3 form-box-input" />
            <b-form-checkbox v-model="copy.copy_roles" class="form-box-input left-text" value="1" unchecked-value="0">
              {{$t('region.copy_roles')}}
            </b-form-checkbox>
            <b-form-checkbox v-model="copy.copy_anal" class="form-box-input left-text" value="1" unchecked-value="0">
              {{$t('region.copy_anal')}}
            </b-form-checkbox>
            <b-button v-b-modal.modal-center2 class="action-button column-span3 auto-margin" variant="clear-blue"><div class="copy-button"></div><span>{{$t('global.button_copy')}}</span></b-button>
         </div>
         <div v-if="updatingOrg">
            <b-alert class="alert-line-height" variant="warning" :show="updatingOrg">
              {{$t('region.updating_organization')}}
              <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
        <div v-if="showAlert">
            <b-alert variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false">
            <span v-html="alertText"></span>
            </b-alert>
        </div>
        <div class="submit-box-2">
          <b-button v-b-modal.modal-center :disabled="updatingOrg" v-if="region.id>0" class="action-button" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button class="action-button" :disabled="updatingOrg" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button" :disabled="updatingOrg" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('region.delete_title')" :no-close-on-backdrop="deletingOrg" :no-close-on-esc="deletingOrg" :hide-header-close="deletingOrg">
          <p class="my-4">{{$t('region.delete_text')}}</p>
          <div v-if="deletingOrg">
            <b-alert class="alert-line-height" variant="warning" :show="deletingOrg">
              {{$t('region.deleting_organization')}}
              <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" :disabled="deletingOrg" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" :disabled="deletingOrg" v-on:click="deleteRegion" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal v-model="showChangeTemplate" centered :title="$t('region.share_template')">
         <div>
          <div id="template-change-box" class=" center-text">
            <span  class="form-box-name">{{$t('region.region_text')}}</span><b-form-select v-model="template_region" :options="officialRegions.filter(v=>v.id != region.id)" class="mb-3" value-field="id" text-field="name"/>
            <b id="template-change-note" class="column-span2">
              {{$t('region.template_change')}}
            </b>
          </div>
         </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showChangeTemplate=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="changeTemplateRegion" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center2" v-model="showCopy" centered :title="$t('region.copy_title')" :no-close-on-backdrop="updatingOrg" :no-close-on-esc="updatingOrg" :hide-header-close="updatingOrg">
          <p class="my-4">{{$t('region.copy_warning')}}</p>
          <div v-if="updatingOrg">
            <b-alert class="alert-line-height" variant="warning" :show="updatingOrg">
              {{$t('region.updating_organization')}}
              <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
          <div v-if="showAlert">
              <b-alert variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false">
              <span v-html="alertText"></span>
              </b-alert>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" :disabled="updatingOrg" v-on:click="showCopy=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" :disabled="updatingOrg" v-on:click="copyRegion" variant="clear-blue"><div class="copy-button"></div><span>{{$t('global.button_copy')}}</span></b-button>
          </div>
        </b-modal>
         <b-modal  v-model="showSignupContactModal" centered :title="$t('region.copy_title')">
          <span class="form-box-title">{{$t('region.region_text')}}</span>
          <div  class="form-box grid-split-3column">
              <span class="form-box-name">{{$t('region.name_text')}}</span><b-form-input v-model="new_contact.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input"></b-form-input>
              <span class="form-box-name">{{$t('region.email_text')}}</span><b-form-input v-model="new_contact.email" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input"></b-form-input>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showSignupContactModal=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="newSignupContact(new_contact)" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#template-change-box{
  grid-template-columns: max-content auto  !important
}
#template-change-note{
  color:red;
}
#startup-chapter-note{
  margin-left:30px;font-size:12px;color:red;
}
#startup-chapter-checkbox, #rst-chapter-checkbox{
  margin-top:30px;
}
.region-chapter-box{
  display:grid;grid-gap: 5px;
}
#signup-contacts-table{
  grid-column: 1/4;margin-top: 30px;
}
</style>
<script>
    
  import router from "@/router"
    export default {
    name: "Region",
    watch:{
      'region.country_id': {
        handler: function () {
           for(var i=0; i<this.countries.length; i++){
             if(this.countries[i].id == this.region.country_id){
               this.region.country = this.countries[i].alpha_2.toUpperCase();
               break;
             }
           }
           this.getTimeZones();
        },
        deep: true
      },
      'copy.copy_to': {
        handler: function () {
           this.getRegions();
        },
        deep: true
      },
      },
      data() {
        return {
          startupAdminNameInUse: false,
          chapterAdminNameInUse: false,
          rstAdminNameInUse: false,
          timezoneOptions: [],
          timezones: [],
          deletingOrg: false,
          updatingOrg: false,
          showChangeTemplate: false,
          statusOptions: [{text: 'All', value: -1},{text: this.$t('subscription.imported_text'), value: 0},{text: this.$t('subscription.pilot_text'), value: 1},{text: this.$t('subscription.trial_text'), value: 2},{text: this.$t('subscription.active_text'), value: 3},{text: this.$t('subscription.inactive_text'), value: 4}],
          showSignupContactModal: false,
          signup_contacts: [],
          new_contact: {name : '', email: ''},
          officialRegions: [],
          regionOptions: [],
          showAlert: false,
          alertText: '',
          showDelete: false,
          countries: [],
          template_region: 0,
          region: {timezones: [], id: 0, name: '', country_id:0, copy_id: 0,event_link: '', official: 1,official_region: 0, address: '', city: '', region: '', country: '', company:'', principal_city: '', subscription_status: 0, postal_code: '', email: '', template_region: 0},
          states: {name: null, country_id: null, admin: null, email: null, pass:null,official_region: null},
          admin: {has_startup: true, has_rst: false, chapter: {org_id: 0,id: 0, name: '', password: '', has_pass: false}, startup: {org_id: 0,id: 0, name: '', password: '', has_pass: false}, rst: {org_id: 0,id: 0, name: '', password: '', has_pass: false}},
          regions: [],
          showCopy: false,
          copy: {copy_to: 0,copy_from: 0, copy_roles: 0, copy_anal: 0},
           fields: [
            {
              key: 'name',
              label: this.$t('region.name_text'),
            },
            { key: 'email',
              label: this.$t('region.email_text'),
            },
             { key: 'delete',
              label: this.$t('region.delete_header'),
              thStyle: {"text-align":"center" }
            },
          ],
          timezoneNames : [
          { name: "(GMT -12:00) Eniwetok, Kwajalein", value: "546" },
          { name: "(GMT -11:00) Midway Island, Samoa", value: "562" },
          { name: "(GMT -10:00) Hawaii", value: "583" },
          { name: "(GMT -9:30) Taiohae", value: "548" },
          { name: "(GMT -9:00) Alaska", value: "577" },
          { name: this.$t('timezone.pacific'), value: "379" },
          { name: this.$t('timezone.mountain'), value: "377" },
          { name: this.$t('timezone.central'), value: "375" },
          { name: this.$t('timezone.eastern'), value: "376" },
          { name: this.$t('timezone.atlantic'), value: "374" },
           { name: this.$t('timezone.eastern_daylight'), value: "213" },
          { name: "Newfoundland Time", value: "378" },
          { name: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "86" },
          { name: "(GMT -2:00) Mid-Atlantic", value: "411" },
          { name: "(GMT -1:00) Azores, Cape Verde Islands", value: "336"},
          { name: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "451" },
          { name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "433" },
          { name: "(GMT +2:00) Kaliningrad, South Africa", value: "446" },
          { name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "458" },
          { name: "(GMT +3:30) Tehran", value: "316" },
          { name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "245" },
          { name: "(GMT +4:30) Kabul", value: "275" },
          { name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "277" },
          { name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "251" },
          { name: "(GMT +5:45) Kathmandu, Pokhara", value: "279" },
          { name: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "259" },
          { name: "(GMT +6:30) Yangon, Mandalay", value: "330" },
          { name: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "246" },
          { name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "360" },
          { name: "(GMT +8:45) Eucla", value: "352" },
          { name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "320" },
          { name: "(GMT +9:30) Adelaide, Darwin", value: "320" },
          { name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "328" },
          { name: "(GMT +10:30) Lord Howe Island", value: "356" },
          { name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "394" },
          { name: "(GMT +11:30) Norfolk Island", value: "552" },
          { name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "528" },
          { name: "(GMT +12:45) Chatham Islands", value: "530" },
          { name: "(GMT +13:00) Apia, Nukualofa", value: "527" }
        ],
        }
      },
        methods: {
          checkAdminName(varName, id, name){
            this.$http.post("/api/member/admin/name/check", {id: id, name: name}).then((response) => {
                if (response.data.success == true) {
                 this.$set(this, varName, response.data.check);
                }
              }).catch(() => {
              });
          },
          changeTemplateRegion(){
            if(this.region.id>0){
              this.$http.post("/api/superadmin/region/change_template", {id: this.region.id, template_region: this.template_region}).then((response) => {
                if (response.data.success == true) {
                 this.region.template_region = this.template_region;
                 this.showChangeTemplate = false;
                }
              }).catch(() => {
              });
            }
          },
          deleteSignupContact(a){
            if(this.region.id>0){
             this.$http.post("/api/superadmin/signupcontacts/delete", a).then((response) => {
              if (response.data.success == true) {
                var index = this.signup_contacts.indexOf(a);
                if (index > -1) {
                  this.signup_contacts.splice(index, 1);
                }
              }
            }).catch(() => {
            });
            }
          },
          newSignupContact(a){
            this.showSignupContactModal = false;
             if(this.region.id>0){
              this.$http.post("/api/superadmin/signupcontacts/new", {id: this.region.id, name: this.new_contact.name, email: this.new_contact.email}).then((response) => {
                if (response.data.success == true) {
                  this.new_contact = {name: '', email: ''};
                  a.id = response.data.id;
                  this.signup_contacts.push(a);
                }
              }).catch(() => {
              });
             }else{
               this.new_contact = {name: '', email: ''};
               this.signup_contacts.push(a);
             }
          },
            getRegions() {
                this.$http.post("/api/superadmin/regions/select/byid", {id: this.copy.copy_to}).then((response) => {
                if (response.data.success) {
                    this.regions = response.data.regions;
                }
                }).catch(() => {
                });
            },
          getCountries(){
              this.$http.post("/api/superadmin/countries", this.region).then((response) => {
                if (response.data.success) {
                  this.countries = response.data.countries;
                }
                this.getRegion();
              }).catch(() => {});
          },
          getRegion() {
            this.region.id = this.$route.params.id;
            if (this.region.id > 0) {
              this.$http.post("/api/superadmin/region/get", this.region).then((response) => {
                if (response.data.success) {
                  this.region = response.data.region;
                  response.data.admin.has_startup = response.data.admin.has_startup==1;
                  response.data.admin.has_rst = response.data.admin.has_rst==1;
                  this.region.core = this.region.core==1;
                  this.timezones = response.data.region.timezones.split(",");
                  this.admin = response.data.admin;
                  this.signup_contacts = response.data.signup_contacts;
                  this.regionOptions = [];
                  if(this.admin.chapter.org_id>0){
                    this.copy.copy_to = this.admin.chapter.org_id;
                    this.regionOptions.push({value: this.admin.chapter.org_id, text: this.$t('region.chapter_text')});
                  }
                  if(this.admin.startup.org_id>0){
                    this.regionOptions.push({value: this.admin.startup.org_id, text: this.$t('region.startup_text')});
                  }
                  if(this.admin.rst.org_id>0){
                    this.regionOptions.push({value: this.admin.rst.org_id, text: this.$t('region.rst_text')});
                  }
                }
              }).catch(() => {});
            }else{
              this.getDefaultCountry();
            }
            
          },
          checkData(){
              this.showAlert = false;
              this.states ={
                  name: null, country_id: null, admin: null, email: null, pass:null
              };
              var allgood = true;
              if(this.startupAdminNameInUse || this.chapterAdminNameInUse || this.rstAdminNameInUse){
                allgood = false;
              }
              if((this.admin.chapter.name == this.admin.startup.name && this.admin.startup.name) || (this.admin.chapter.name == this.admin.rst.name && this.admin.rst.name != "") || (this.admin.startup.name == this.admin.rst.name && this.admin.rst.name != "")){
                allgood = false;
              }
              if(this.region.name.length==0){
                  allgood = false;
                  this.states.name = false;
              }
             if(this.region.country_id==0){
                  allgood = false;
                  this.states.country_id = false;
              }
              if(this.admin.chapter.name.length==0){
                  allgood = false;
              }
              if(this.admin.has_startup && this.admin.startup.name.length==0){
                  allgood = false;
              }
              if(this.admin.has_rst && this.admin.rst.name.length==0){
                  allgood = false;
              }
              if(this.timezones.length == 0){
                allgood = false;
              }
              
              if((this.admin.chapter.password && this.admin.chapter.password.length==0 &&  this.region.id==0)
              || (this.admin.chapter.password!==this.admin.chapter.password_confirm && this.admin.chapter.password && this.admin.chapter.password.length>0)){
                  this.states.pass = false;
                  allgood = false;
              }
              if(this.admin.has_startup && ((this.admin.startup.password && this.admin.startup.password.length==0 &&  this.region.id==0)
              || (this.admin.startup.password!==this.admin.startup.password_confirm && this.admin.startup.password && this.admin.startup.password.length>0))){
                  allgood = false;
              }
              if(this.admin.has_rst && ((this.admin.rst.password && this.admin.rst.length==0 &&  this.region.id==0)
              || (this.admin.rst.password!==this.admin.rst.password_confirm && this.admin.rst.password && this.admin.rst.password.length>0))){
                  allgood = false;
              }
              this.showAlert = !allgood;
              if(this.showAlert){
                this.alertText = this.$t('region.alert_text')
              }
              return allgood;
          },
          updateRegion() {
            if(!this.checkData()){
              return;
            }
            this.updatingOrg = true;
            this.region.timezones = this.timezones.join(",");
            this.region.postal_code = this.region.postal_code.substring(0,20);
            this.$http.post("/api/superadmin/region/update",{region: this.region, admin: this.admin,signup_contacts: this.signup_contacts}).then((response) => {
              this.updatingOrg = false;
              if (response.data.success === true) {
                this.saveVariant = 'success';
                this.back();
              } else {
                this.saveVariant = 'danger';
              }
            }).catch(() => {this.updatingOrg = false;this.$bvToast.show('serverError');});
          },
          getDefaultCountry() {
            this.$http.post("/api/superadmin/getdefaultcountry",{}).then((response) => {
              if (response.data.success === true) {
                this.region.country_id = response.data.country_id;
              } 
            }).catch(() => {this.$bvToast.show('serverError');});
          },
          deleteRegion() {
            this.deletingOrg = true,
            this.$http.post("/api/superadmin/region/delete", this.region).then((response) => {
              this.deletingOrg = false;
              if (response.data.success === true) {
                this.back();
              }else if(response.data.chapters>0){
                this.showDelete = false;
                this.alertText = this.$t('region.chapters_present')
                this.showAlert = true;
              }
            }).catch(() => {this.deletingOrg = false;});
          },
           copyRegion() {
             //this.copy.copy_to = this.region.id;
             if(this.copy.copy_to>0 && this.copy.copy_from>0 && this.copy.copy_from != this.region.id){
              this.updatingOrg = true;
               this.$http.post("/api/superadmin/region/copy", this.copy).then((response) => {
                this.updatingOrg = false;
                if (response.data.success === true) {
                  this.showCopy = false;
                }else{
                  this.showAlert = true;
                  this.alertText = this.$t('region.copy_error')
                }
              }).catch(() => {this.updatingOrg = false;this.showAlert = true;
                  this.alertText = this.$t('region.copy_error')});
             }
            
          },
          back(){
            window.history.length > 1 ? router.go(-1) : router.push("/admin/regions");
          },
          getOfficialRegions(){
            this.$http.post("/api/regions/official/get", {}).then((response) => {
              if (response.data.success === true) {
                this.officialRegions = response.data.regions;
                this.officialRegions.unshift({id: 0, name: this.$t('region.no_template')})
              } 
            }).catch(() => {});
          },
          getTimeZones() {
            this.timezoneOptions = [];
        this.$http.post("/api/organization/timezones/country", {country_id: this.region.country_id}).then((response) => {
          if (response.data.success === true) {
            for(var i=0; i<this.timezoneNames.length; i++){
              if(response.data.timezone_ids_string.indexOf(this.timezoneNames[i].value)>-1){
                this.timezoneOptions.push(this.timezoneNames[i]);
              }
            }
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
    },
 destroyed() {
      this.removeClass(document.getElementById("menu-regions"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-regions"), "active-menu");
      this.getCountries();
      this.getOfficialRegions();
    }
    }
</script>
